.gallery {
  margin-top: 36px;
  padding-top: 98px;
  padding-bottom: 30px;

  background-color: #212529;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .gallery-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .gallery-col {
    position: relative;
    width: 261px;
    overflow: hidden;
    margin-bottom: 20px;
    
    transition: all .3s ease;
    cursor: pointer;

    @include on-event {
      .title-holder {
        opacity: 1;
      }
    }

    img {
      width: 261px;
      height: 261px;
      object-fit: cover;
    }

    .title-holder {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #0c7abf;
      font-size: 15px;
      color: #fff;
      padding: 8px 15px;
      z-index: 1;

      opacity: 0;

      transition: all .3s ease;
    }
  }
}

.modal {
  z-index: 111111;

  .img-holder {
    img {
    width: 600px;
    margin: 0 auto;
    display: block;
    }
  }

  .modal-content {
    background-color: #212529;
  }

  p {
    color: #fff;
    font-family: "Open Sans";
    font-size: 17px;
    text-transform: uppercase;
  }

  .close {
    opacity: 1;
    color: #fff;
  }
}

@include respond-to(lg-down) {
  .gallery {
    .gallery-col {
      width: 32%;

      img {
        width: 100%;
      }
    }
  }
}

@include respond-to(md-down) {
  .gallery {
    .gallery-col {
      width: 48%;

      img {
        width: 100%;
      }
    }
  }

  .modal {
    .img-holder {
      img {
        width: 100%;
      }
    }
  }
}

@include respond-to(sm-down) {
  .gallery {
    .gallery-col {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}