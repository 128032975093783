//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.index-page {
  margin-top: 36px;

  .swiper-button-next {
    top: 47%;
    right: 139px;
    background-image: url('../assets/images/arrow.png');
    background-size: contain;
    outline: none;
  }

  .swiper-button-prev {
    top: 47%;
    left: 139px;
    transform: rotate(180deg);
    background-image: url('../assets/images/arrow.png');
    background-size: contain;
    outline: none;
  }

  .section-hero {
    position: relative;
    height: 446px;
    overflow: hidden;

    img {
      width: 100%;
    }

    .hero-content {
      z-index: 1;
      padding-top: 158px;
      padding-left: 73px;
    }

    .swiper-slide {
      height: 445px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    h1 {
      margin-bottom: 1rem;
      color: #4eabd1;
      font-family: 'Arial Black';
      font-size: 35px;
      font-weight: bolder;
    }

    p {
      width: 430px;
      max-width: 100%;
      color: #3a3a3a;
      font-family: 'Arial';
      font-size: 14px;
    }
  }

  .section-about {
    padding-top: 44px;

    p {
      margin-bottom: 20px;
      max-width: 100%;
      color: #7a7a7a;
      font-family: 'Arial';
      font-size: 14px;
      line-height: 16px;
    }

    h2 {
      margin-bottom: 9px;
    }

    .flex-holder {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .image-holder img{
      width: 91%;
    }

    .image-holder {
      width: 58%;
    }

    .about-content {
      width: 42%;
    }
  }

  .about-ceo {
    min-height: 244px;
    background-size: cover;
    background-image: url('../assets/images/banner-2.jpg');
    background-repeat: no-repeat;

    .quote {
      padding-top: 68px;
      font-family: Arial;
      color: #fff;
      font-style: italic;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
      width: 700px;
      margin: 0 auto 35px;
      max-width: 100%;
    }

    .name {
      margin-bottom: 0;
      text-align: center;
      font-family: Arial;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }

    .position {
      margin-bottom: 0;
      text-align: center;
      font-family: Arial;
      color: #fff;
      font-weight: bold;
      font-size: 10px;
    }
  }

  .section-service {
    position: relative;
    padding-top: 44px;

    h2 {
      text-align: center;
    }

    .service-holder {
      margin-top: 25px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .box-holder {
      width: 32%;
      height: 500px;
      display: flex;
      align-items: center;
      position: relative;
      transform-style: preserve-3d;
      transition: all 1s ease-in-out;
      background-image: url('../assets/images/banner-3.jpg');
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        transform: rotateY(180deg);
      }

      &:nth-child(2) {
        background-image: url('../assets/images/banner-4.jpg');
      }

      &:nth-child(3) {
        background-image: url('../assets/images/banner-5.jpg');
      }

      &:nth-child(4) {
        background-image: url('../assets/images/banner-6.jpg');
      }

      .side {
        backface-visibility: hidden;
        position: absolute;
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .box-back {
        padding: 25px;
        color: #0087cc;
        text-align: center;
        transform: rotateY(180deg);

        p {
          font-size: 14px;
          margin-top: 15px;
          color: #565656;
          font-weight: 600;
        }

        h2 {
          font-size: 25px;
        }
      }

      img {
        width: 94px;
        margin: 0 auto;
        display: block;
      }

      .title {
        width: 250px;
        text-align: center;
        margin: 0 auto;
        font-family: "Arial Black";
        text-align: center;
        color: #565656;
      }
    }
  }

  .section-album {
    margin-top: 57px;
    
    .flex-holder {
      display: flex;
    }
  
    .right-holder {
      min-height: 440px;
    }

    .btn-blue {
      margin-top: 21px;
    }
    
    .left-holder {
      width: 57%;
      min-height: 440px;
      padding: 105px 55px 0;
      background-image: url('../assets/images/banner.jpg');

      p {
        margin-top: 20px;
        font-family: "Arial";
        font-size: 15px;
        color: #565656;
      }
    }

    .row-holder {
      display: flex;
      align-items: center;
      height: 222px;
      overflow: hidden;

      .img-lg {
        width: 289px;
        height: 222px;
        object-fit: cover;
      }

      .img-sm {
        width: 188px;
        height: 222px;
        object-fit: cover;
      }
    }
  }

  .section-cta {
    margin-top: 62px;
    padding-top: 32px;
    padding-bottom: 32px;
    background-image: url('../assets/images/banner-7.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
      margin-bottom: 30px;
      color: #fff;
      text-align: center;

      span {
        color: #164b61;
      }
    }

    li,
    a {
      margin-bottom: 15px;
      color: #164b61;
      font-family: Arial;
      font-size: 15px;
      width: 250px;
      font-weight: 800;
    }

    .icon {
      color: #fff;
      margin-right: 10px;
    }

    .media-holder {
      display: flex;
      justify-content: space-between;
      width: 670px;
      margin: 0 auto;
    }
  }
}

@include respond-to(xl) {
  .index-page {
    .section-hero .hero-content {
      padding-left: 0;
    }
  }
}

@include respond-to(lg-down) {
  .index-page {
    .section-hero .hero-content {
      padding-left: 0;
    }

    .section-service {
      .box-holder {
        width: 24%;

        .box-back {
          p {
            font-size: 12px;
          }

          h2 {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include respond-to(md-down) {
  .index-page {
    .section-about {
      .flex-holder {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      .image-holder,
      .about-content {
        width: 100%;
      }

      .image-holder img {
        width: 100%;
        margin: 0 auto;
        display: block;
      }
    }

    .section-service {
      .service-holder {
        flex-wrap: wrap;
      }

      .box-holder {
        width: 48%;
        margin-bottom: 25px;

        background-size: cover;

        .box-back p{
          font-size: 14px;
        }
      }
    }

    .section-album {
      .left-holder,
      .right-holder {
        width: 100%;
      }

      .flex-holder {
        flex-wrap: wrap;
      }

      .row-holder {
        .img-lg {
          width: 60%;
        }

        .img-sm {
          width: 40%;
        }
      }
    }
  }
}

@include respond-to(sm-down) {
  .index-page {
    .section-hero {
      .hero-content {
        padding-left: 0;
      }

      h1 {
        font-size: 25px;
      }

      .swiper-slide {
        background-position: left;
      }
    }

    .about-ceo {
      min-height: auto;
      padding-bottom: 60px;
    }

    .section-service {
      .box-holder {
        width: 100%;
      }
    }

    .section-album {
      .left-holder {
        padding: 45px 20px 0;
      }

      .row-holder {
        display: block;
        height: auto;

        .img-lg,
        .img-sm {
          width: 100%;
        }
      }
    }

    .section-cta {
      .media-holder {
        display: block;
        width: 100%;
      }

      li,
      a {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
