//main: ../main.scss

/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: 400 125% / 1.4 $font-opensans;
}

h2 {
  font-family: "Open Sans";
  font-weight: 700;
  color: #7a7a7a;
  font-size: 30px;

  span {
    font-family: "Arial Black";
    color: #4eabd1;
  }

  @include respond-to(sm-down) {
    font-size: 25px;
  }
}
