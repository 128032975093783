//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.top-header {
  height: 36px;
  background-color: #0c7abf;
  transition: all .3s ease;

  .link {
    color: #fff;
    font-family: "Open Sans";
    font-size: 12px;
  }

  .icon {
    position: relative;
    padding-right: 16px;
    font-size: 15px;

    &.fa-instagram {
      top:  2px;
      font-size: 18px;
    }

    &.fa-envelop {
      top: 3px;
      font-size: 18px;
    }
  }

  .icon-holder:last-child {
    .link:first-child {
      margin-right: 55px;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1111;

  .navbar {
    padding: 0
  }

  .main-header {
    border-bottom: 1px solid #d6d6d6;
    background-color: #fff;

    transition: all .3s ease;
  }

  .nav-link {
    color: #464646;
    font-size: 13px;
    font-weight: bold;
    font-family: "Arial";
  }
}

.is-click {
  .nav-link {
    color: #0c7abf;
  }
}

.fixed-header {
  .top-header {
    height: 0;
  }

  .main-header {
    background-color: #fff;
  }
}

@include respond-to(sm-down) {
  .top-header {
    .icon-holder:last-child {
      display: none;
    }

    .link:last-child .icon {
      padding-right: 0;
    }

    .container {
      justify-content: flex-end;
    }
  }

  button[aria-expanded="true"]{
    header {
      background-color: #fff
    }
  }

  header {
    .navbar-expand-md>.container {
      padding: 0 15px;
    }

    .navbar-toggler .icon-bar {
      height: 3px;
      width: 23px;
      background: #329dc8;

      &+.icon-bar {
        margin-top: 5px;
      }
    }
  }
}