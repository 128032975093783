//main: ../../main.scss
// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: $navbar-padding-y $navbar-padding-x;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properities so that content nested within behave properly.
  > .container,
  > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;

  @include hover-focus {
    text-decoration: none;
  }
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}


// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  font-size: $navbar-toggler-font-size;
  line-height: 1;
  background-color: transparent; // remove default button style
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus {
    text-decoration: none;
  }

  // Opinionated: add "hand" cursor to non-disabled .navbar-toggler elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        > .container,
        > .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @include media-breakpoint-up($next) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        .navbar-nav {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .dropdown-menu-right {
            right: 0;
            left: auto; // Reset the default from `.dropdown-menu`
          }

          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        > .container,
        > .container-fluid {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
        }

        .navbar-collapse {
          display: -webkit-box!important;
          display: -ms-flexbox!important;
          display: flex!important;  // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none;
        }

        .dropup {
          .dropdown-menu {
            top: auto;
            bottom: 100%;
          }
        }
      }
    }
  }
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}
