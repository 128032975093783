//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn-blue {
  width: 140px;
  height: 33px;
  margin-top: 16px;
  color: #fff;
  font-family: "Arial";
  font-weight: bolder;
  font-size: 12px;
  background: #4eabd1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  border: 1px solid transparent;

  @include on-event {
    color: #4eabd1;
    background: none;
    border: 1px solid #4eabd1;
  }
}