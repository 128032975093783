//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  background-color: #0c7abf;
  color: #fff;
  font-family: "Open Sans";
  font-size: 12px;
  text-align: right;
  padding: 11px 0;
}